<template>
  <div class="row">
    <institution-view-template :in-nebraska="true" :institution-type="'ESU'" :institution-data="esu"
                               :contact-users="contactUsers"/>
  </div>
  <div v-if="authUser.position.roleHierarchy === 1">
    <router-link :to="{name: 'EditEsu', params: {idEsu: idEsu}}">
      <button class="btn-primary button" id="btn-primary button">Edit This ESU</button>
    </router-link>
  </div>
  <div v-else>
    <hr/>
    <h6>
      <span style="font-weight: bold; color: black;">
        Contact the Educational Service Unit Coordinating Council to Edit/Update Contact Information
      </span>
    </h6>
  </div>
  <hr/>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";
import {useStore} from "vuex";
import InstitutionViewTemplate from "@/views/SuperUserViews/EducationalOrganizationManagement/InstitutionViewTemplate";

export default {
  name: "EsuView",
  components: {InstitutionViewTemplate},
  props: {
    idEsu: Number
  },
  setup(props, context) {
    const store = useStore();
    const esu = ref({});
    const authUser = computed(() => store.getters['auth/user']);
    const contactUsers = ref([]);
    const districtIds = ref([]);
    const schoolIds = ref([]);

    onBeforeMount(() => {
      getEsu();
      getEsuUsers();
      getSchoolIds();
      getDistrictIds();
    })

    async function getEsu() {
      let get_uri = API_URL + "/esu/displayById?_idEsu=" + props.idEsu;
      await axios.get(get_uri)
          .then((result) => {
            esu.value = prepareEsuData(result.data);
          })
    }

    async function getEsuUsers() {
      let role_hierarchy = 2;

      if (props.idEsu >= 20) role_hierarchy = 1;
      if (props.idEsu === 22) role_hierarchy = 6;
      let get_uri = API_URL + "/user/byOrgAndRole?_idOrganization=" + props.idEsu + "&_roleHierarchy=" + role_hierarchy;
      await axios.get(get_uri)
          .then((result) => {
            contactUsers.value = result.data;
          })
    }


    async function getSchoolIds() {
      while (schoolIds.value.length > 0) {
        schoolIds.value.pop();
      }
      let get_uri = API_URL + "/school/participating/byEsu?_idEsu=" + props.idEsu;
      let tmp_ids = [];
      await axios.get(get_uri)
          .then((result) => {
            // console.log(result.data);
            for (let i = 0; i < result.data.length; i++) {
              tmp_ids[i] = result.data[i].idSchool;
            }
          }).finally(() => {
            schoolIds.value = tmp_ids;
          })
    }

    async function getDistrictIds() {
      while (schoolIds.value.length > 0) {
        districtIds.value.pop();
      }
      let get_uri = API_URL + "/district/participating/byEsu?_idEsu=" + props.idEsu;
      let tmp_ids = [];
      await axios.get(get_uri)
          .then((result) => {
            // console.log(result.data);
            for (let i = 0; i < result.data.length; i++) {
              tmp_ids[i] = result.data[i].idDistrict;
            }
          }).finally(() => {
            districtIds.value = tmp_ids;
          })
    }


    function prepareEsuData(esu) {
      return {
        name: esu['esuName'],
        primaryAddress: esu['primaryEsuAddress'],
        phoneNumber: esu['esuPhoneNumber'],
        city: esu['esuCity'],
        cdn: esu['esuCdn'],
        idEsu: esu['idEsu'],
        zipCode: esu['esuZipCode'],
        faxNumber: esu['esuFaxNumber'],
        secondaryAddress: esu['secondaryEsuAddress']
      }
    }

    return {
      esu,
      authUser,
      contactUsers,
      districtIds,
      schoolIds
    }
  }
}
</script>

<style scoped>

</style>